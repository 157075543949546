/* globals zc $ Backbone app utils mw analytics */

(function () {
  var session = window.session

  zc.routers.Main = Backbone.Router.extend({
    routes: {
      // '': 'landingPage',
      // 'signup': 'signup',
      // 'pricing': 'pricing',
      'login': 'login',
      'logout': 'logout',
      // 'account': 'account',
      // 'account/billing': 'billing',
      // 'reset-password': 'resetPassword',
      // 'reset-password/*hash': 'resetPassword', // we use a * here since our hashes can contain slashes
      'email-verification': 'emailVerification',
      'email-verification/:hash': 'emailVerification',
      // 'cloud-drive': 'clouddriveReceiver',
      // 'dashboard': 'dashboard',
      ':username/:slug': 'project',
      ':username/:slug/:recordingId': 'project',
      'unsupportedbrowser': 'unsupportedBrowser',
      'kicked': 'kicked',
      'alreadyJoined': 'alreadyJoined',
      'notfound': 'notFound',
      '*path': 'notFound'
    },

    landingPage: function () {
      var landingPage = new Backbone.Model()
      landingPage.View = zc.views.LandingPageView
      app.set({ location: landingPage })
    },

    signup: function () {
      if (session.loggedIn) {
        window.location.pathname = '/dashboard'
        return
      }

      var signupPage = new Backbone.Model()
      signupPage.View = zc.views.SignupPageView
      app.set({ location: signupPage })
    },

    login: function () {
      var queryParams = utils.getQueryStringParams()
      var couponQueryStringParam = queryParams['coupon'] || ''
      var couponCode = utils.sanitizeText(couponQueryStringParam)
      var modelAttrs = {}
      if (utils.canUseCoupon(couponCode)) {
        modelAttrs.couponCode = couponCode
      }
      var loginPage = new Backbone.Model(modelAttrs)
      loginPage.View = zc.views.LoginPageView
      app.set({ location: loginPage })
    },

    logout: function () {
      localStorage.removeItem('___t')
      if (window.parent) {
        window.parent.postMessage('signedOut', servars.creatorPlatformUrl)
      } else {
        window.location.href = '/login'
      }
    },

    account: mw.use(mw.auth, mw.loadUser, mw.connect, mw.checkVerified, mw.checkActive, function () {
      var accountPage = app.user
      accountPage.View = zc.views.AccountPageView
      app.set({ location: accountPage })
    }),

    billing: mw.use(mw.auth, mw.loadUser, mw.connect, mw.checkActive, function () {
      var billingPage = app.user

      //
      app.socket.emit('billing:read', { customerId: app.user.get('customerId') }, function (err, invoices) {
        if (err) {
          console.error(err)
          app.router.navigate('/account', { trigger: true })
          return
        }

        app.user.subscription.invoices = invoices.data || []

        billingPage.View = zc.views.BillingPageView
        app.set({ location: billingPage })
      })
    }),

    resetPassword: function (hash) {
      var resetPasswordPage = new Backbone.Model({ hash: hash })
      resetPasswordPage.View = zc.views.ResetPasswordPageView
      app.set({ location: resetPasswordPage })
    },

    emailVerification: function (hash) {
      if (hash) {
        $.ajax({
          url: '/api/email-verify',
          type: 'POST',
          data: { hash: hash }
        })
          .success(function (verifiedUser) {
            if (verifiedUser) {
              var firstTimeVerified = verifiedUser.firstTimeVerified

              if (firstTimeVerified) {
                analytics.track('Email Verified', {
                  username: verifiedUser.username,
                  email: verifiedUser.email
                })
                app.user.set('isVerified', true)
              }

              // most of the time this will work as it used to before jwt
              // the user will be logged in, in case he isn't (because too much time passed since signup)
              // the user will be taken to the login page
              app.router.navigate('/dashboard', { trigger: true })
              utils.notify('success', 'Email verification successful.', { ttl: 3000 })
            }
          })
          .error(function () {
            // if there is an error with verifying the hash
            utils.notify('error', 'Invalid token.')
            app.router.navigate('/login', { trigger: true })
          })
      } else {
        var emailVerificationPage = new Backbone.Model()
        emailVerificationPage.View = zc.views.EmailVerificationPageView
        app.set({ location: emailVerificationPage })
      }
    },

    clouddriveReceiver: mw.use(mw.loadUser, mw.connect, function () {
      var receiverPage = new Backbone.Model()
      receiverPage.View = zc.views.DropboxReceiverPageView

      var provider, accessToken
      var queryParams = utils.getQueryStringParams()

      if (queryParams['error']) {
        if (queryParams['error'] === 'access_denied') {
          utils.notify('alert', 'You need to accept the prompt from Google in order to connect Zencastr to Google Drive.')
        } else {
          utils.notify('error', 'Could not connect Zencastr to Google Drive.')
        }
        app.router.navigate('/dashboard', { trigger: true })
        return
      }

      // if we have query params then it's google
      if (queryParams['state'] === 'google') {
        provider = 'google'
        accessToken = queryParams['code']
      } else {
        provider = 'dropbox'
        var match = window.location.href.match(/access_token=([^&]*)/)
        // if we don't have something here then it's certanly an error
        if (!match) {
          app.router.navigate('/dashboard', { trigger: true })
          utils.notify('error', 'Could not connect to cloud provider. Please try again or contact support.')
          return
        }
        accessToken = match[1]
      }

      var cloudToken = {
        provider: provider,
        token: accessToken
      }
      app.socket.emit('user:cloudDrive:token:save', cloudToken, function (err, tokenProvider) {
        if (err) {
          utils.notify('error', err)
        } else {
          app.user.set('cloudToken', tokenProvider)
          analytics.track('Cloud Drive Connected', {
            provider: accessToken.provider
          })

          // show a success notification
          var providerTitleCase = provider === 'google' ? 'Google Drive' : 'Dropbox'
          utils.notify('success', 'Zencastr successfully connected to ' + providerTitleCase, { ttl: 3000 })
        }

        // either way, we need to redirect to dashboard
        app.router.navigate('/dashboard', { trigger: true })
      })
    }),

    pricing: function () {
      // if the coupon is missing this will be undefined
      // make sure it's an empty string, because in edge when we run utils.sanitizeText it will become `undefined`
      var queryParams = utils.getQueryStringParams()
      var couponQueryStringParam = queryParams['coupon'] || ''
      var intervalQueryStringParam = queryParams['interval'] || ''
      var signUpOnlyMode = queryParams['signup'] || false

      var couponCode = utils.sanitizeText(couponQueryStringParam)
      var interval = utils.sanitizeText(intervalQueryStringParam)

      // check to see if the interval has a valid value
      if (['month', 'year'].indexOf(interval) < 0) {
        interval = 'month'
      }
      var modelAttrs = { interval: interval, signUpOnlyMode: !!signUpOnlyMode }
      if (utils.canUseCoupon(couponCode)) {
        modelAttrs.couponCode = couponCode
      }
      var pricingPage = new Backbone.Model(modelAttrs)
      pricingPage.View = zc.views.PricingPageView
      app.set({ location: pricingPage })
    },

    dashboard: mw.use(mw.browserSupport, mw.auth, mw.loadUser, mw.connect, mw.checkVerified, mw.checkActive, mw.cloudDrive, mw.persistStorage, function () {
      var queryParams = utils.getQueryStringParams()
      var couponQueryStringParam = queryParams['coupon'] || ''
      var couponCode = utils.sanitizeText(couponQueryStringParam)
      var modelAttrs = {}
      if (utils.canUseCoupon(couponCode)) {
        modelAttrs.couponCode = couponCode
      }
      var dashboard = new zc.models.Dashboard(modelAttrs)
      dashboard.View = zc.views.DashboardView
      app.set({ location: dashboard })
    }),

    project: mw.use(mw.browserSupport, mw.loadUser, mw.connect, mw.persistStorage, mw.logger, function (username, slug, recordingId) {
      console.log('Reached recording page', [username, slug, recordingId].join('/'))
      datadogRum.setUser({
        id: app.user.id,
        name: app.user.attributes.login,
        email: app.user.attributes.email,
        plan: app.user.subscription.attributes.planBillingId,
        roles: app.user.attributes.roles
      })
      app.socket.emit('projects:read', {
        query: { owner: username, slug: slug },
        options: { deleted: true }
      }, function (err, projects) {
        if (err || !projects.length) {
          if (!projects.length) {
            console.error('Could not find project', [username, slug, recordingId].join('/'))
          }
          // don't load a project that doesn't exist
          return app.router.notFound()
        }

        var project = projects[0]
        var recoverMode = false

        // if the project is archived, show a different page
        if (project.deletedAt) {
          var projectArchived = new Backbone.Model(project)
          projectArchived.View = zc.views.ProjectArchivedView
          app.set({ location: projectArchived })
          return
        }

        // if recover is in the url then the guest wants to recover
        if (recordingId === 'recover') {
          recordingId = null
          recoverMode = true
          console.log('Reached recover link')
        }

        project = new zc.models.Project(project, { activeRecordingId: recordingId })
        var showGreenroom = project.attributes.ownerId !== app.user.id
        app.user.set('greenroom', showGreenroom)

        // if the user should be on the green room, load the host's settings
        if (showGreenroom) {
          app.user.settings.set(projects[0].settings)
        }

        project.View = showGreenroom ? zc.views.GreenroomView : zc.views.ProjectView

        // service down notification
        // utils.notify('alert', '<p><strong>Degraded Service Warning</strong></p><p>There is currently an outage on <a target="_blank" href="https://status.aws.amazon.com/">Amazon\'s S3 Storage</a> that is causing dropbox uploads to fail.  Please avoid making new recordings while this outage persists.  Live updates on this issue will be tweeted from the <a target="_blank" href="https://twitter.com/zencastr">@zencastr</a> twitter account</p><p>Very sorry for the inconvenience.</p>');

        app.project = project
        app.set({ location: project })
        app.socketConnection.initSocketEvents(app)
        project.joinRoom()

        if (recoverMode) {
          // if the user is on the green room, wait for him to exit
          if (showGreenroom) {
            app.once('change:location', function () {
              project.recoverTracks()
            })
          } else {
            project.recoverTracks()
          }
        }
      })
    }),

    unsupportedBrowser: function (notification) {
      var page = new Backbone.Model()
      page.View = zc.views.UnsupportedBrowserView
      app.set({ location: page })
      if (notification) {
        utils.notify('alert', notification)
      }
    },

    kicked: function () {
      var kicked = new Backbone.Model()
      kicked.View = zc.views.KickedView
      app.set({ location: kicked })
    },

    alreadyJoined: function () {
      var alreadyJoined = new Backbone.Model()
      alreadyJoined.View = zc.views.AlreadyJoinedView
      app.set({ location: alreadyJoined })
    },

    notFound: function () {
      window.location.href = servars.creatorPlatformUrl + window.location.pathname
    }
  })
})()
