/* globals zc Backbone app utils ua jwtClient */

(function () {
  'use strict'

  var session = window.session

  function getLoggedInUserAndUpdateSession () {
    return jwtClient.fetchWithJwt('/me').then(function (res) {
      if (res.status === 401) { return null } else if (res.ok) {
        return res.json()
      } else {
        alert('An unexpected error occurred, please try again later')
      }
    }).then(function (user) {
      if (user) {
        session.loggedIn = true
        session.user = user
        session.userId = user._id
      } else {
        session.loggedIn = false
      }
      return user
    })
  }

  function getLoggedInAdminAndUpdateSession () {
    return jwtClient.fetchWithJwt('/admin_me').then(function (res) {
      if (res.status === 403 || res.status === 401) {
        return null
      } else if (res.ok) {
        return res.json()
      } else {
        alert('An unexpected error occurred, please try again later')
      }
    }).then(function (user) {
      if (user) {
        session.loggedIn = true
        session.user = user
        session.userId = user._id
      } else {
        session.loggedIn = false
      }
      return user
    })
  }

  window.mw = {
    // main middleware function
    use: function () {
      var mwArgs = arguments
      return function () {
        var middlewares = Array.prototype.slice.call(mwArgs)
        var route = middlewares.pop()
        var routerArgs = Array.prototype.slice.call(arguments)

        var recurseMiddleware = function () {
          if (middlewares.length <= 1) {
            middlewares.shift()(function () {
              route.apply(route, routerArgs)
            })
          } else {
            middlewares.shift()(recurseMiddleware)
          }
        }

        if (middlewares.length) {
          recurseMiddleware()
        } else {
          route.apply(route, routerArgs)
        }
      }
    },

    connect: function (cb) {
      // connect socket.io socket
      if (app.socket) {
        cb()
      } else {
        app.socketConnection = new zc.models.SocketConnection()
        app.socket = Backbone.socket = app.socketConnection.connect().socket

        // this handler only fires the first time a socket connection is made
        // this ensures that the middleware callback chain doesn't get called again
        app.socket.once('connected', function () { // when a successful connection is made
          cb()
        })
      }
    },

    auth: function (cb) {
      if (!session.loggedIn) {
        getLoggedInUserAndUpdateSession().then(function (user) {
          if (user) {
            app.set({ user: new zc.models.User(user) })
            cb()
          } else {
            app.router.navigate('/login', { trigger: true })
          }
        })
      } else {
        cb()
      }
    },

    authForAdmin: function (cb) {
      if (!session.loggedIn) {
        getLoggedInAdminAndUpdateSession().then(function (user) {
          if (user) {
            app.set({ user: new zc.models.User(user) })
            cb()
          } else {
            // redirect the user to the landing page
            // router won't work because it isnt' configured in admin
            window.location.href = '/'
          }
        })
      } else {
        cb()
      }
    },

    logout: function (cb) {
      if (session.loggedIn) {
        app.socket.emit('user:logout', function (err) {
          if (err) return cb(err)
          app.user.setLoggedOut()
          cb()
        })
      } else {
        cb()
      }
    },

    loadUser: function (cb) {
      if (app.user) return cb()

      function proceedAsGuest (invite, cb) {
        var username = invite.username || 'guest'
        var savedUserId = localStorage.getItem('userId')
        var userId = savedUserId || utils.slugify(username) + Math.round(Math.random() * 1000000)
        localStorage.setItem('userId', userId)

        var email
        if (invite && invite.email) {
          email = invite.email
          localStorage.setItem('inviteEmail', invite.email)
        } else if (localStorage.getItem('inviteEmail')) {
          email = localStorage.getItem('inviteEmail')
        } else {
          email = undefined
        }
        app.set({ user: new zc.models.User({ _id: userId, username: username, greenroom: true, email }) })
        cb()
      }

      getLoggedInUserAndUpdateSession().then(function (user) {
        if (!app.user && session.user) {
          app.set({ user: new zc.models.User(session.user) })
          cb()
        } else {
          var queryParams = utils.getQueryStringParams()
          // make sure we actually have values that we want to sanitize
          // this will create a guest user
          var username = queryParams.username && utils.sanitizeText(queryParams.username)
          var forceLogin = queryParams.forceLogin && utils.sanitizeText(queryParams.forceLogin)
          // if this is an invite link
          var invite = queryParams.invite && utils.sanitizeText(queryParams.invite)

          // if we have the userId query param then it's a guest that comes back
          var userId = queryParams.userId && utils.sanitizeText(queryParams.userId)
          if (userId) {
            localStorage.setItem('userId', userId)
          }

          // if the user is not logged in and it has the forceLogin url param
          // then he came from an email url. force him to login
          if (forceLogin) {
            // remove this class here
            $('.app').removeClass('page-loading')
            var loginModal = new zc.views.ModalView({
              addClass: 'login-modal',
              force: true,
              noBlurBackground: true,
              ChildView: zc.views.LoginFormView,
              callback: function (error) {
                if (!error) {
                  // refresh the page without the forceLogin param
                  // this looses all other url params. not a problem I think
                  window.location = window.location.origin + window.location.pathname
                }
              }
            })
            loginModal.render()
          } else if (invite) {
            proceedAsGuest({}, cb)
            // sockets connection isn't established yet at this point
            // proper fix is to extract the handling of the invitation out of loading the user
            // and doing it after a socket connection has been establiched (mw.connect)
            /* app.socket.emit('invite:read', { id: invite }, function (err, invite) {
              // if we have a name saved on the invite, use that
              if (!err && invite && invite.name) {
                proceedAsGuest(invite, cb)
              } else {
                // if not, show the modal to request the name
                proceedAsGuest({}, cb)
              }
            }) */
          } else if (!username) {
            proceedAsGuest({}, cb)
          } else {
            // If the arg is present and valid, use it
            if (utils.validateNickname(username)) {
              proceedAsGuest(username, cb)
            } else {
              // if not, reload and loose it
              window.location.href = window.location.origin + window.location.pathname
            }
          }
        }
      })
    },

    browserSupport: function (cb) {
      var browser = ua.browser.name.toLowerCase()
      var version = Number(ua.browser.version.split('.')[0])
      var isMsEdge = browser === 'edge'
      var isFirefox = ['firefox'].indexOf(browser) > -1
      var isMobile = utils.isMobile()
      var isOfficiallySupported = ['chrome', 'chromium'].indexOf(browser) > -1
      var minVersion = zc.conf.minBrowserVersion[browser]
      var latestVersion = zc.conf.latestBrowserVersion[browser]
      var meetsMinVersionReq = version >= minVersion
      var supportsUserMedia = navigator.mediaDevices && navigator.mediaDevices.getUserMedia

      if (isOfficiallySupported && meetsMinVersionReq && supportsUserMedia && window.AudioContext && window.indexedDB && !isMsEdge && !isFirefox && !isMobile && window.Promise && window.URL && window.Blob && window.Worker) {
        cb()
      } else {
        var notification
        if (isOfficiallySupported && !meetsMinVersionReq) {
          notification = 'Please update to the latest version <strong>' + browser + ' ' + latestVersion + '</strong> and return. You are currently using <strong>' + browser + ' ' + version + '</strong> which is outdated.'
        } else if (isMobile) {
          notification = 'Sorry, Zencastr only works on desktop and laptop computers.  Mobile devices aren\'t supported yet.'
        }
        app.router.unsupportedBrowser(notification)
      }
    },

    cloudDrive: function (cb) {
      var cloudToken = app.user.get('cloudToken')
      app.cloudDrive = new zc.models.CloudDrive(cloudToken)
      cb()
    },

    logger: function (cb) {
      if (window.Logger) {
        window.Logger.enableDebugModule()
      }
      cb()
    },

    persistStorage: function (cb) {
      if (navigator.storage && navigator.storage.persist) {
        navigator.storage.persist().then(function (granted) {
          if (granted) {
            console.log('Storage will not be cleared except by explicit user action')
          } else {
            console.warn('Storage may be cleared by the UA under storage pressure.')
          }
          app.user.set('storagePersistence', granted)
          cb()
        })
      } else {
        cb()
      }
    },

    checkActive: function (cb) {
      // if a user hasn't paid, redirect them to the payment page
      if (app.user.subscription.isActive() || app.user.subscription.trialing()) {
        cb()
      } else {
        console.log('Account not current')
        app.router.navigate('/pricing', { trigger: true })
        utils.notify('alert', 'Your account is not active. Please select a plan.', { ttl: 3000 })
      }
    },

    /**
     * used to check if the user verified it's email address
     * if not it will call emailVerification which shows the 'please very email' popup
     * @param  {Function} cb    Called in case of success
     */
    checkVerified: function (cb) {
      if (!app.user || !app.user.get('isVerified')) {
        app.router.emailVerification()
      } else {
        cb()
      }
    }
  }
})()
